<template>
  <div class="report-container">
    <div class="report-title-box">
      <div>已开发票</div>
      <div class="title-right">
        <span class="service">客服电话：</span>
        <span>{{servicePhone}}</span>
      </div>
    </div>
    <div class="divider"></div>
    <div v-if="total == 0" style="margin-top: 150px">
      <empty name="暂无数据"></empty>
    </div>
    <div class="report-content" v-if="total != 0">
      <div class="report-box">
        <el-table
          :data="tableData"
          style="width: 100%"
          header-cell-class-name="table_header"
          height="645px"
        >
          <el-table-column type="index" label="序号" width="80"></el-table-column>
          <el-table-column
            prop="orderCode"
            label="订单编号"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column
            prop="commodityName"
            label="购买详情"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column prop="paymentAmount" label="支付金额（元）">
            <template slot-scope="scope"
              >￥{{ calcNum(scope.row.paymentAmount) }}</template
            >
          </el-table-column>
          <el-table-column
            prop="paymentDate"
            label="支付时间"
            width="180"
          ></el-table-column>
          <el-table-column prop="" label="电子发票">
            <template slot-scope="scope">
              <span
                v-if="scope.row.invoiceState == '60581001'"
                class="view-style"
                @click="download(scope.row)"
                >下载</span
              >
              <span
                v-if="scope.row.invoiceState != '60581001'"
                class="view-style"
                style="cursor: not-allowed"
                >待开票</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <pagination
          ref="pagination"
          :total="total"
          @change="paginChange"
          v-show="total != 0"
        >
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Empty from "@/components/Empty";
import { mapGetters } from "vuex";
export default {
  components: {
    Pagination,
    Empty,
  },
  data() {
    return {
      total: 0,
      tableData: [],
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      imgUrl:null
    };
  },
  computed:{
    ...mapGetters(['servicePhone'])
  },
  created() {},
  mounted() {
    this.pageConsumerFinance();
  },
  methods: {
    // 获取列表
    pageConsumerFinance() {
      this.$api.personalCenter
        .pageConsumerFinance({ ...this.params, type: 2 })
        .then((res) => {
          this.tableData = res.rows;
          this.total = res.total;
        });
    },
    // 保留两位小数方法
    calcNum(val) {
      return Number(val).toFixed(2);
    },
    // 切换分页
    paginChange(page, pageSize) {
      this.params.pageNum = page;
      this.params.pageSize = pageSize;
      this.pageConsumerFinance();
    },
    // 下载按钮
    download(val) {
      window.open(val.invoicePdfUrl, "_blank");
      // const loading = this.$loading({
      //   lock: true,
      //   text: "下载中...",
      //   spinner: "el-icon-loading",
      //   background: "rgba(255, 255, 255, 0.3)",
      //   fullscreen: false,
      // });
      // this.$api.personalCenter
      //   .downloadConsumerFinance({ consumerFinanceId: val })
      //   .then((res) => {
      //     if (res.code == 200) {
      //       loading.close();
      //       this.imgUrl=res.data.file
      //       this.$utils.downloadIamge(res.data.file, "发票信息");
      //     }
      //   })
      //   .catch((msg) => {
      //     if (msg?.msg) {
      //       this.$message.error(msg?.msg);
      //     }
      //     loading.close();
      //   });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
